import React, { useEffect } from 'react';
import styles from '../styles/merch.module.css';

const MerchPage: React.FC = () => {
  useEffect(() => {
    const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

    const loadScript = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      document.body.appendChild(script);

      script.onload = () => {
        if ((window as any).ShopifyBuy) {
          const client = (window as any).ShopifyBuy.buildClient({
            domain: 'tid5k0-rp.myshopify.com',
            storefrontAccessToken: 'ddded5620a209e6ef640ba7f23bfb990',
          });

          (window as any).ShopifyBuy.UI.onReady(client).then((ui: any) => {
            ui.createComponent('product', {
              id: '8870584516866', // Replace with your verified product ID
              node: document.getElementById('shopify-buy-button'),
              moneyFormat: '%24%7B%7Bamount%7D%7D',
              options: {
                product: {
                  styles: {
                    product: {
                      '@media (min-width: 601px)': {
                        'max-width': 'calc(25% - 20px)',
                        'margin-left': '20px',
                        'margin-bottom': '50px',
                      },
                    },
                    button: {
                      'font-family': 'Avant Garde, sans-serif',
                      ':hover': { 'background-color': '#d05044' },
                      'background-color': '#e7594b',
                      ':focus': { 'background-color': '#d05044' },
                    },
                  },
                  text: { button: 'Add to cart' },
                },
              },
            });
          });
        }
      };
    };

    loadScript();
  }, []);

  return (
    <main className={styles.merchPage}>
      <h1 className={styles.title}>Ohio Merch</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Exclusive Ohio Merchandise</h2>
        <p className={styles.text}>
          Show your Ohio pride with our exclusive merch collection! From fanum apparel to sigma-level accessories, we've
          got you covered. Explore our unique lineup and mog your Ohio love with style.
        </p>
        <div id="shopify-buy-button" className={styles.shopifyButton}></div>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Why Shop with Us?</h2>
        <p className={styles.text}>
          When you shop with us, you're not just getting quality merch – you're supporting the Ohio fanum spirit. Every
          purchase contributes to local initiatives and helps mog the community. Skibidi with us and make a difference!
        </p>
      </section>

      <section className={styles.ctaSection}>
        <h2 className={styles.subTitle}>Visit Our Full Store</h2>
        <p className={styles.text}>
          Want more merch? Check out our full collection of Ohio-themed goodies at our online store. From limited
          editions to fanum classics, there’s something for everyone!
        </p>
        <a
          href="https://makeohiogoonagain.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.ctaButton}
        >
          Explore the Store
        </a>
      </section>
    </main>
  );
};

export default MerchPage;
