import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';
import montageImage from '../../assets/images/montagel60new1better.jpg';
import WalletBalances from '../WalletBalances';

const Sidebar: React.FC = () => {
  const onlineServices = [
    { name: 'X.com', link: 'https://x.com/BrainrotOhio' },
    { name: 'Get OHIO', link: 'https://raydium.io/swap/?inputMint=sol&outputMint=8VRfgKJY5CnfgGkstisB3QgLPWM6xYBK4esrfN5Hpump' },
    { name: 'Get Merch', link: '/merch' },
    { name: 'Explore Ohio', link: '/Explore-Ohio' },
    { name: 'Gooning Parks', link: '/subpages/Gooning-parks' },
    { name: 'DMV', link: '/subpages/DMVPage' },
    { name: 'Telegram', link: 'https://t.me/brainrotportal0' },
    { name: 'DexScreener', link: 'https://dexscreener.com/solana/dvr4yu4eimun5ufpsjl3mevnkx2lzz2eldtxljsh1b7d' },
  ];

  return (
    <aside className={styles.sidebar}>
      <img src={montageImage} alt="Montage" className={styles.montageImage} />
      <h2>ONLINE SERVICES:</h2>
      <ul>
        {onlineServices.map((service, index) => (
          <li key={index}>
            {service.link.startsWith('http') ? (
              // External link
              <a href={service.link} target="_blank" rel="noopener noreferrer">
                {service.name}
              </a>
            ) : (
              // Internal link
              <Link to={service.link}>{service.name}</Link>
            )}
          </li>
        ))}
      </ul>
      <div className={styles.walletBalancesContainer}>
        <WalletBalances />
      </div>
    </aside>
  );
};

export default Sidebar;
